body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --bg-color: #150326;
    --text-color: #f6efff;
    --dim-text-color: #a19bb2;
    --guess-text-color: #f6efff;
    --choice-color: #74697c;
    --guess-color: #5f259a;
    --share-color: #319a25;
    --correct-color: #02b301; /*🟩*/
    --arrow-color: #6f8eac; /*⬆️*/
    --trophy-color: #ffed68; /*🏆*/
    --wrong-color: #c42626;
    --link-color: #bb6bff;
    --graph-color: #881bf8;
    --input-bg-color: #301b44;
    --guess-bg-color: black;

    background: var(--bg-color);
    color: var(--text-color);

    width: 100%;
    position: fixed;
    overflow-x: visible;
}

@media (prefers-color-scheme: light) {
    body {
        --bg-color: #f6efff;
        --guess-bg-color: white;
        --text-color: #150326;
        --dim-text-color: #7c7a81;
        --choice-color: #c8c0cc;
        --input-bg-color: #f6efff;
        --link-color: #881bf8;
        --graph-color: #ae5eff;
        --arrow-color: #81accc; /*⬆️*/
    }
}

.Hide {
    display: none;
}

a {
    color: var(--link-color);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Container {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    height: 100vh;
}

.MainColumn {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.ContentWrapper {
    width: 500px;
    overflow-x: visible;
    padding-bottom: 2em;
}

@media (max-width: 500px) {
    .ContentWrapper {
        width: 100vw;
    }
}

.Buffer {
    flex-grow: 1;
}

.Title {
    font-family: 'NotoTitle';
    font-weight: bold;
    text-transform: uppercase;
}

h1, h2, h3, h4 {
    font-family: 'NotoTitle';
    text-transform: uppercase;
    margin: 5px;
    text-align: center;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    border-bottom: 2px solid var(--text-color);
    font-weight: bold;
}

.Header h1 {
    margin: 0;
    flex: 3;
}

.Header .IconSet {
    flex: 1;
}

.Header .IconSet.Left {
    text-align: left;
}

.Header .IconSet.Right {
    text-align: right;
}

.Icon {
    cursor: pointer;
    margin: 0 5px;
    font-size: 28px;
    color: var(--text-color);
}


.Body {
    width: 100%;
    flex-grow: 1;
    padding: 1em;
    box-sizing: border-box;
}

.WordContainer {
    margin: 0;
    text-align: center;
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
}

.WordContainer #word {
    font-size: 50px;
    font-family: "NotoSans Script", "NotoSans";
    line-height: 60px;
    margin-top: 10px;
}
.WordContainer #word.vertical {
    writing-mode: vertical-lr;
}

.WordContainer #romanization {
    font-size: 35px;
    line-height: 40px;
    font-family: "NotoSerif";
}

.WordContainer #ipa {
    font-size: 20px;
    font-family: "NotoSerif Italic";
    color: var(--dim-text-color);
}

.WordContainer #meaning {
    font-size: 15px;
    font-family: "NotoSans Light";
}

.GuessWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

table.Guesses {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
    font-size: 18px;
}

table.Guesses tr {
    height: 30px;
}

.Guess td {
    background-color: var(--choice-color);
    margin: 0;
    text-align: center;
}

.Guess td:nth-child(2) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Guess td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Guess.Hints td {
    background-color: var(--guess-bg-color);
    color: var(--text-color);
    border: 1px solid var(--text-color);
}

.Guess td.Description {
    margin: 0;
    padding: 0;
    border: 0;
    width: 0;
}

.Guess.Hints td.Language {
    padding: 0 8px;
}

.Guess.Hints td.Language,
.Guesses th.HintIcon.Language {
    min-width: calc(100% - 30px * 5);
}

.Guess.Hints td:not(.Language):not(.Description),
.Guesses th.HintIcon:not(.Language) {
    width: 30px;
    box-sizing: border-box;
}

.Guess.Hints td[data-value='true'] {
    background-color: var(--correct-color);
}

.Guess.Hints td.Direction {
    background-color: var(--arrow-color);
    transform-origin: center;
}
.Maps .Guess.Hints td.Direction {
    cursor: pointer;
}

.Guess.Hints td.Direction[data-value='true'] {
    background-color: var(--correct-color);
    color: var(--trophy-color);
}


.HintIcon {
    font-size: 20px;
    text-align: left;
    position: relative;
}

.HintIcon > * {
    margin-left: 5px;
    box-sizing: content-box;
}

.Description {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    display: block;
}

.MapTip {
    position: absolute;
    bottom: calc(2em + 13px);
    right: 0;
    width: 10em;
    padding: 5px;
    border: 1px solid var(--text-color);
    color: var(--text-color);
    background-color: var(--bg-color);
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
    transition: opacity 300ms;
    pointer-events: none;
}

.MapTip:after {
    content: "";
    position: absolute;
    bottom: -15px;
    right: 4px;
    border-width: 15px 10px 0;
    border-style: solid;
    border-color: var(--text-color) transparent;
    display: block;
    width: 0;
    z-index: 0;
}

.Guess.Tried .HintBlock {
    margin: 0;
    padding: 0;
    cursor: default;
}

.MapWrapper {
    position: relative;
    transition: height 300ms ease-in-out;
    overflow: hidden;
    width: calc(300px + 3em);
    display: flex;
    justify-content: center;
}

.MapClose {
    position: absolute;
    right: 0;
}

.LookupSection {
    width: 100%;
    padding: 0;
    box-sizing: border-box;
}

button.Guess,
.Lookup {
    width: 100%;
    border-radius: 5px;
    height: 30px;
}

.ToolTip {
    position: relative;
}

.ToolTip:before {
    content: attr(title);
    position: absolute;
    left: -3em;
    top: -2.5em;
    margin-left: 15px;

    padding: 5px;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    background: var(--bg-color) !important;
    color: var(--text-color);
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    pointer-events: none;

    display: none;
    white-space: nowrap;
    z-index: 100;
}
.ToolTip[data-title]:before {
    content: attr(data-title);
}

.ToolTip.Side:before {
    top: calc(50% - 15px);
    left: calc(50% + 20px);
}

.ToolTip.TipBelow:before {
    top: 30px;
}

.ToolTip:hover:before {
    display: block;
}

label.Hidden {
    display: none;
}

.LookupWrapper {
    position: relative;
    width: 100%;
}

.Lookup {
    width: 100%;
    background-color: var(--input-bg-color);
    color: var(--text-color);
    border: 2px var(--text-color) solid;
    padding: 0 5px;
    margin: 0;
    box-sizing: border-box;
    font-size: 16px;
}


.LangList {
    margin: 0;
    padding: 0;
    background-color: var(--input-bg-color);
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    width: 100%;
    bottom: 100%;
}

.Lang {
    border: 1px solid var(--text-color);
    border-radius: 5px;
    padding: 5px;
    margin: 3px 0;
    list-style: none;
    cursor: pointer;
}

.Lang.Selected {
    border: 2px solid var(--link-color);
}

button.Guess {
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'NotoTitle';
    height: 2.5em;
    font-size: 18px;
}

.MakeGuess {
    background-color: var(--guess-color);
    color: var(--guess-text-color);
    cursor: pointer;
}

.MakeGuess:disabled {
    cursor: default;
    opacity: 50%;
}

.Share {
    background-color: var(--correct-color);
    color: var(--guess-text-color);
    cursor: pointer;
}

.Share.Fail {
    background-color: var(--wrong-color);
}

.ShareBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ShareData {
    display: flex;
    align-items: start;
    padding: 0;
    border: 2px solid var(--text-color);
    border-top-width: 0;
    width: calc(100% - 1em);
    max-width: calc(100vw - 2em);
    box-sizing: border-box;
}

.Foldable {
    transition: height 600ms, opacity 600ms;
    overflow: hidden;
}

.ShareOptions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 1em;
}

button.ShareOption {
    background-color: var(--bg-color);
    color: var(--text-color);
    border-color: var(--text-color);
    border-left-width: 0;
    font-family: NotoTitle;
    text-transform: uppercase;
    height: 3.5em;
    text-align: left;
}

button.ShareOption.Selected {
    background-color: var(--text-color);
    color: var(--bg-color);
}

button.ToggleShareOptions {
    position: fixed;
    right: 0;
    bottom: 0;
    font-family: NotoTitle;
    text-transform: uppercase;
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid var(--text-color);
    border-bottom: 0;
    border-right: 0;
    padding: 5px;
    font-size: 10px;
}

.ShareContent {
    padding: 5px;
}

.Overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: opacity 300ms;
}

.ModalContainer {
    position: relative;
    width: auto;
    margin: auto;
    border: 4px solid #AAA;
    background-color: var(--bg-color);
    padding: 0 2em 1em 2em;
    border-radius: 1em;
    max-height: 100vh;
    max-width: 35em;
    box-sizing: border-box;
    overflow-y: scroll;
}

.Close {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 25px;
    cursor: pointer;
    height: 0;
    display: inline-block;
}

.StatsList {
    display: flex;
}

.StatBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin: 10px;
    flex: 1;
}

.Stat {
    font-size: 40px;
    font-weight: bold;
    font-family: NotoTitle;
}

.StatLabel {
    font-size: 15px;
    text-align: center;
}

.Distribution li {
    margin: 8px 0;
    font-weight: bold;
    background-color: var(--graph-color);
    padding: 2px;
    padding-right: 0.5em;
    min-width: 1em;
}

.GraphLabel {
    text-align: right;
}

.HelpList {
    list-style-type: none;
}

.InfoContent {
    text-align: center;
}
.InfoContent a {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.InfoContent .links .title {
    font-size: 18px;
    font-weight: bold;
}
.InfoContent .links .description {
    margin-left: 10px;
}

.HelpList {
    padding: 0;
}

.HelpList li > *:first-child {
    margin-right: 0.5em;
    width: 1em;
}

.Message {
    width: 100%;
    padding: 15px;
    margin: 0;
    text-align: center;
    transition: opacity 1500ms;
    box-sizing: border-box;
}
.Message:empty {
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
}
.Message * {
    width: 100%;
}

fieldset ul {
    list-style: none;
    padding: 0;
}

svg.icon {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  width:1em;
  height:1em;
  display:inline-block;
  -webkit-flex-shrink:0;
  -ms-flex-negative:0;
  flex-shrink:0;
  -webkit-transition:fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition:fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  fill:currentColor;
  font-size:1.5rem;
}